<template>
    <!-- TODO: Use re-field to get validations -->
    <re-input :model-value="selectedDateTime?.toLocaleString()" v-bind="$attrs">
        <q-popup-proxy transition-show="scale" transition-hide="scale">
            <div class="row">
                <q-date v-model="selectedDateTime" mask="YYYY-MM-DD HH:mm" first-day-of-week="1"></q-date>
                <q-time v-model="selectedDateTime" mask="YYYY-MM-DD HH:mm" format24h></q-time>
            </div>
            <div class="row items-center justify-end">
                <q-btn label="Clear" color="main" flat @click="selectedDateTime = null"></q-btn>
                <q-btn v-close-popup label="Close" color="main" flat></q-btn>
            </div>
        </q-popup-proxy>
    </re-input>
</template>
<script setup>
const selectedDateTime = defineModel({
    type: [String, Date, null],
    set(value) {
        if (value === null) return null
        return new Date(value)
    },
})
</script>
