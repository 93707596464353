import { computed, reactive } from 'vue'
import { useMutation, useQuery, useQueryClient } from '@tanstack/vue-query'
import utils from '@/shared/plugins/utils'
import store from '@/store'

export default function useUser() {
    const query = useQuery({
        queryKey: ['auth', 'user'],
        placeholderData: {},
        enabled: () => store.getters.isAuthenticated,
        staleTime: 1000,
    })

    const queryClient = useQueryClient()

    const { mutateAsync } = useMutation({
        onMutate: ({ data }) => {
            const previous = queryClient.getQueryData(['auth', 'user'])
            const optimistic_next = { ...previous, ...data }
            queryClient.setQueryData(['auth', 'user'], optimistic_next)
            // Return context with the optimistic update
            return { previous, optimistic_next }
        },
        onSuccess: (result) => {
            // Update the cache with the final data
            queryClient.setQueryData(['auth', 'user'], result)
        },
        onError: (error, variables, context) => {
            // Remove optimistic update
            queryClient.setQueryData(['auth', 'user'], context.previous)
        },
    })

    const userId = computed(() => query.data?.value.id)
    const username = computed(() => query.data?.value.username)

    const patch = (data) => {
        return mutateAsync({
            verb: 'PATCH',
            queryKey: ['user', query.data.value.username],
            data,
        })
    }

    /*
    ██████   ██████  ██      ███████ ███████
    ██   ██ ██    ██ ██      ██      ██
    ██████  ██    ██ ██      █████   ███████
    ██   ██ ██    ██ ██      ██           ██
    ██   ██  ██████  ███████ ███████ ███████
    */

    const roles = computed(() => query.data?.value.roles || {})

    const hasRole = (role, module, only) => {
        if (!query.data?.value.roles) return false

        if (!utils.containsRole(roles.value, role, module)) return false
        if (only) {
            // TODO: decide if "only" means across modules or not:
            return Object.values(roles.value).flat().length == 1
        }
        return true
    }

    // TODO: check if we need both this reactive and top-level's reactive?
    const is = reactive({
        admin: computed(() => {
            return hasRole('admin', undefined, false)
        }),
        valuer: computed(() => {
            return hasRole('valuer', undefined, false)
        }),
        dvmSubmitter: computed(() => {
            return hasRole('submitter', 'dvm', false)
        }),
        dvmDispatcher: computed(() => {
            return hasRole('dispatcher', 'dvm', false)
        }),
        dvmValuer: computed(() => {
            return hasRole('valuer', 'dvm', false)
        }),
        ovmValuer: computed(() => {
            return hasRole('valuer', 'ovm', false)
        }),
        oepcValuer: computed(() => {
            return hasRole('valuer', 'oepc', false)
        }),
        onlyValuer: computed(() => {
            return hasRole('valuer', undefined, true)
        }),
        ovmDispatcher: computed(() => {
            return hasRole('dispatcher', 'ovm', false)
        }),
        onlyBorrower: computed(() => {
            return hasRole('borrower', undefined, true)
        }),
    })

    const accessibleModules = computed(() => {
        const modules = Object.entries(roles.value)
            .filter(([k, v]) => v.length && !['-', '*'].includes(k))
            .filter(([k, _]) => k !== 'cat') // TODO: enable catalog back
            .map(([k, _]) => k)

        // dmv:submitter can view and edit borrower info on ovm requests:
        if (!modules.includes('ovm') && is.dvmSubmitter.value) {
            modules.push('ovm')
        }
        return modules
    })

    const submitableModules = computed(() => {
        return Object.entries(roles.value)
            .filter(([k, v]) => v.length && !['-', '*'].includes(k))
            .filter(([_, v]) => v.includes('submitter'))
            .map(([k, _]) => k)
    })

    const submitableValuations = computed(() => {
        return submitableModules.value.filter((m) => ['avm', 'dvm', 'ovm'].includes(m))
    })

    const availableApps = computed(() => {
        const apps = []
        if (submitableValuations.value.length > 0) apps.push('valuation')
        if (submitableModules.value.includes('ers')) apps.push('ers')
        if (submitableModules.value.includes('oepc')) apps.push('oepc')
        return apps
    })

    return reactive({
        ...query,
        // TODO: remove this after finishing the refactor:
        // user: query.data,
        patch,
        hasRole,
        roles,
        is,
        // TODO: move this to a sub-object?
        accessibleModules,
        submitableModules,
        submitableValuations,
        availableApps,
        userId,
        username,
        authenticated: computed(() =>
            query.data ? query.data.value.authenticated : store.getters.isAuthenticated
        ),
    })
}
