import { inject } from 'vue'
import { createRouter, createWebHistory } from 'vue-router'

import { i18n, SUPPORTED_LOCALES, setLocale, loadLocale } from '@/i18n.js'

import store from '@/store/index.js'
import utils from '@/shared/plugins/utils'

import { onlineManager } from '@tanstack/vue-query'
import { Actions } from '@/store/Auth'

// With this we make the lang param can only contain those in langs
// Since `/:lang/` could also match `/dashboard/`
const langRegex = SUPPORTED_LOCALES.join('||')

function prefixRoutes(prefix, routes) {
    return routes.map((route) => ({
        ...route,
        path: `/${prefix}${route.path}`,
    }))
}

import routes from './routes.js'

const setupRouter = (config) => {
    const prefixedRoutes = prefixRoutes(`:lang(${langRegex})?`, routes)

    const router = createRouter({
        history: createWebHistory(config.FRONTEND_URL),
        routes: prefixedRoutes,
        scrollBehavior(to, from, _savedPosition) {
            if (to.hash) {
                return {
                    el: to.hash,
                    behavior: 'smooth',
                    top: 70,
                }
            }
            if (to.name === 'report' || to.name === from.name) {
                return
            }
            return { x: 0, y: 0 }
        },
    })

    // LANGUAGE
    router.beforeEach(async (to) => {
        // console.debug(
        //     `Router Language beforeEach ${to.params.lang} | ${i18n.global.locale.value} | ${to.params.lang !== i18n.global.locale.value}`
        // )

        // Language if not provided
        if (!to.params.lang) {
            const browserLang = navigator.language.split('-')[0]
            const matchingLocale = SUPPORTED_LOCALES.includes(browserLang)
                ? browserLang
                : SUPPORTED_LOCALES.find((locale) => locale.startsWith(browserLang))
            await setLocale(matchingLocale)
        }

        // Language provided and different than current
        if (to.params.lang && i18n.global.locale.value !== to.params.lang) {
            await setLocale(to.params.lang)
        }

        // Lazy loading language
        if (!i18n.global.availableLocales.includes(i18n.global.locale.value)) {
            await loadLocale(i18n.global.locale.value)
        }

        // TODO: can we inject lang at the router-link level, so it appears in link href?
        if (!to.params.lang && !to.meta.resetLangParam && i18n.global.locale.value) {
            return { ...to, params: { ...to.params, lang: i18n.global.locale.value } }
        }

        // TODO: refactor this / move to a route-specific beforeEnter guard
        // if (to.params.action === 'start' && to.redirectedFrom) {
        //     console.debug('Redirected from', to.redirectedFrom, to.params)
        //     // update localestorage lang on landing
        //     // TODO: why are we storing the short lang and not the full ISO code?
        //     const lang = to.redirectedFrom.???.split('/')[1]
        //     localStorage.setItem('lang', lang)
        // }
    })

    // // AUTHENTICATION
    router.beforeEach(async (to) => {
        // console.debug('Router Authentication beforeEach', to)

        const config = inject('config')

        if (config.CHECK_LOGIN && !to.meta?.public && !store.state.auth.authenticated) {
            await store.dispatch(
                onlineManager.isOnline() ? Actions.CHECK_AUTHENTICATION : Actions.CHECK_OFFLINE_AUTHENTICATION
            )

            if (store.state.auth.authenticated) {
                if (to.name === 'login') {
                    console.debug('Authenticated, redirecting to valuation.request', to.params)
                    return {
                        name: 'valuation.request',
                        params: { lang: to.params.lang, action: 'start' },
                    }
                } else return
            }

            // if (to.name === 'login' && to.query.msg) return
            if (to.name === 'login') {
                return
            }
            let org_hint = utils.getCookie('re-org-hint')
            let redirect_query_param = to.query.r ? `&r=${to.query.r}` : ''
            if (config.ROCKESTATE_SSO_ENABLED && config.ROCKESTATE_SSO_URL && org_hint === 'rockestate') {
                window.location.href = config.ROCKESTATE_SSO_URL + redirect_query_param
                return false
            } else if (config.CUSTOMER_SSO_ENABLED && config.CUSTOMER_SSO_URL) {
                window.location.href = config.CUSTOMER_SSO_URL + redirect_query_param
                return false
            } else if (config.UNAUTHORIZED_URL) {
                window.location.href = config.UNAUTHORIZED_URL
                return false
            }

            return { name: 'login', params: { lang: to.params.lang } }
        }
    })

    // REQUEST REF
    router.beforeEach((to) => {
        if (to.query.valuation_request_ref !== undefined) {
            store.commit('SET_REQUEST_REF', to.query.valuation_request_ref)
        } else {
            // NOTE: reset of curRequest can be triggered:
            // 1. at route level, with route.meta.resetCurRequest
            // 2. at query level, with query.resetCurRequest
            // TODO: move toward splitting routes and handling everything through meta
            if (to.meta.resetCurRequest || to.query.resetCurRequest) {
                store.commit('RESET_REQUEST_REF')
            } else if (store.getters.getRequestRef) {
                return { ...to, query: { ...to.query, valuation_request_ref: store.getters.getRequestRef } }
            }
        }
    })

    // // ROUTE GUARDS
    router.beforeEach(async (to, _from) => {
        // console.debug('Router Route Guards beforeEach from -> to:', from, to)
        if (!to.name) return
        //ERS
        // if (to.name.startsWith('ers.')) {
        //     const config = inject('config')

        //     // TODO: a whole lot of page-specific logic was removed here.
        //     // Check whether it needs to be added back to individual components
        // }

        //VALUATION
        // TODO: replace actions by routes
        // Make sure the action is valid
        const actionList = [
            'start',
            'enter',
            'building-type',
            'address',
            'map-select',
            'confirm-main-building',
            'select-main-building',
            'select-parcels',
            'confirm-main-parcel',
            'select-main-parcel',
            'select-extra-parcels',
            'view-building-parcels',
            'check-apartment-floor',
            'flood-assessment',
            'extra-info',
            'renovation-info',
            'edit',
            'ovm',
        ]
        if (to.name === 'valuation.request' && to.params.action && !actionList.includes(to.params.action)) {
            return {
                name: 'valuation.request',
                params: { lang: to.params.lang, action: 'start' },
            }
        }
    })

    // TODO: improve error handling to include full trace before re-enabling:
    // router.onError((err) => {
    //     const error = {
    //         error_code: 'router_error',
    //         debug: {
    //             message: `Vue Router error: ${err.message}`,
    //             traceback: err.stack,
    //         },
    //     }
    //     store.dispatch(Actions.LOG_ERROR, { error, errorType: 'error' })
    // })

    // Needed to avoid errors being thrown when doing a .push() without the language
    // (as the beforeEach will add it automatically, but result in a different target route)
    // https://github.com/vuejs/vue-router/issues/2881#issuecomment-520554378
    // const originalPush = Router.prototype.push
    // Router.prototype.push = function push(location, onResolve, onReject) {
    //     if (onResolve || onReject)
    //         return originalPush.call(this, location, onResolve, onReject)
    //     return originalPush.call(this, location).catch((err) => {
    //         if (Router.isNavigationFailure(err)) {
    //             // resolve err
    //             return err
    //         }
    //         // rethrow error
    //         return Promise.reject(err)
    //     })
    // }

    return router
}

export default setupRouter
