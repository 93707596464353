import { i18n } from '@/i18n'
import store from '@/store'
import { useGlobals } from '@/main'
const t = i18n.global.t

const getColumns = ({ curUser, isUserRequests, requests }) => {
    const { $format } = useGlobals()
    return [
        {
            name: 'deployment',
            label: t('monolith.shared.customer'),
            width: '30',
            visible: store.getters.getConfig.DISPATCHED_DEPLOYMENTS?.length > 0,
            searchable: true,
            align: 'left',
        },
        {
            name: 'customer_ref',
            label: 'Customer ref',
            width: '40',
            align: 'center',
        },
        {
            name: 'valuation_request_ref',
            visible: !store.getters.getConfig.DISPATCHED_DEPLOYMENTS?.length > 0,
            label: t('monolith.shared.request_ref'),
            align: 'center',
        },
        {
            name: 'valuation_type',
            visible: curUser.accessibleModules.length > 1,
            label: t('monolith.shared.type'),
            align: 'center',
        },
        {
            name: 'building_type',
            label: t('features.f_building_type'),
            align: 'center',
            format: (buildingType) => (buildingType ? t(`value.${buildingType}`) : ''),
        },
        {
            name: 'displayAddress',
            label: t('monolith.shared.address'),
            align: 'center',
        },
        {
            name: 'status',
            label: t('monolith.shared.status_col'),
            align: 'center',
        },
        {
            name: 'created_at',
            label: t('monolith.shared.created_at'),
            align: 'center',
            format: $format.date,
            searchable: store.getters.getConfig.SHOW_DATE_FILTERS,
        },
        {
            name: 'modified_at',
            label: t('monolith.shared.last_modified'),
            align: 'center',
            format: $format.date,
            searchable: store.getters.getConfig.SHOW_DATE_FILTERS,
        },
        {
            name: 'action',
            label: t('monolith.shared.actions'),
            align: 'center',
            searchable: false,
            sortable: false,
        },
        {
            name: 'owner',
            label: t('monolith.shared.owner'),
            visible: !isUserRequests,
            align: 'center',
            format: (owner) => (owner ? owner.email : ''),
            // searchable: !isCustomerRefSearch,
        },
        {
            name: 'borrower',
            label: t('monolith.shared.borrower'),
            visible:
                curUser.hasRole('dispatcher') ||
                requests?.some(
                    (request) => request.owner?.is_self && request.borrower && !request.borrower?.is_self
                ),
            align: 'center',
        },
        {
            name: 'last_submission_lang',
            label: t('monolith.global.language'),
            visible: curUser.hasRole(['dispatcher', 'valuer']),
            align: 'center',
            // searchable: !isCustomerRefSearch,
        },
        {
            name: 'valuer',
            label: t('monolith.shared.valuer'),
            visible: curUser.hasRole('dispatcher'),
            align: 'center',
            format: (valuer) => (valuer?.assigned ? valuer.username : 'N/A'),
            // searchable: !isCustomerRefSearch,
        },
    ].map((column) => ({ field: column.name, visible: true, sortable: true, ...column }))
}

export { getColumns }
